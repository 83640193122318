import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  Facebook,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Amanda Monaco"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Amanda Monaco"
        videoUrl="https://vimeo.com/493921013/87bf04667d"
      />
      <Width pt="3.6rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"nicholas"}
                fluid={data.amandaMonacoImage.childImageSharp.fluid}
              />
            </Box>
          </Box>

          <Box width={["100%", null, 8 / 12, null]}>
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Amanda Monaco
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and educator
            </Text>
            <Box>
              <Link to="https://www.instagram.com/guitarmonaco/" as="a">
                <Instagram mr="1.2rem" />
              </Link>
              <Link to="https://www.facebook.com/GuitarMonaco/" as="a">
                <Facebook />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Guitarist/composer Amanda Monaco has performed at venues such as
              Jazz at Lincoln Center, Birdland, Jazz Standard and Flushing Town
              Hall, and with artists such as{" "}
              <strong>Milt Hinton, Steve Wilson, Rufus Reid</strong>, and the
              <strong>Mingus Orchestra</strong>. She has released six albums to
              date and her current jazz projects include her eclectic quartet
              Deathblow, rambunctious organ quartet Glitter, and all-female jazz
              sextet <strong>Lioness</strong>. Her New Music ensemble The{" "}
              <em>Pirkei Avot</em> Project performs her original music with
              lyrics from selected verses (from a collection of rabbinical
              teachings with the same name) compiled in the third century C.E.
            </Text>
            <Text level="2">
              Amanda’s guitar playing is “well within the modernist canon, but
              quite distinctive: her phrasing is often made up of tartly
              dissonant chords and insistent yet off-kilter repetitions that
              remind one of Grant Green by way of Andrew Hill… her playing is
              utterly unique, a breath of fresh air in the cookie-cutter climes
              of both mainstream and free jazz.” (Clifford Allen,{" "}
              <em>The New York City Jazz Record</em>)
            </Text>
            <Text level="2">
              An educator since 1990, Amanda is an Associate Professor at
              <strong>Berklee College of Music</strong> where she teaches
              private instruction, labs and guitar ensembles. Amanda’s book,{" "}
              <strong>
                <em>Jazz Guitar for the Absolute Beginner</em>
              </strong>{" "}
              (Alfred Publishing), is available worldwide.
            </Text>
            <Text level="2">
              Amanda is the Artistic Director of Convergence Arts, Inc., a
              501(c)(3) not-for-profit organization whose mission is to share
              the art and fun of improvisation with people of all ages. Since
              2013, Convergence has received funding from the Queens Council on
              the Arts for myriad projects including the Six String Summit
              Guitar Festival, The Queens Jazz OverGround Spring Jazz Fest, and
              the <strong>Lioness: Women in Jazz concert series</strong>.
            </Text>
            <Text level="2">
              Amanda received her M.A.from The{" "}
              <strong>City College of New York</strong> in 2008. In her
              undergraduate years, Amanda studied with{" "}
              <strong>Ted Dunbar, Kenny Barron, Rufus Reid</strong> and{" "}
              <strong>Harold Mabern</strong>. She received her B.Mus from
              <strong>William Paterson University</strong>.
            </Text>
          </Box>
          
        </Box>
      </Width>
      <MentorFooter data={data} name="Amanda Monaco" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    amandaMonacoImage: file(
      relativePath: { eq: "amandaMonaco-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
